import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import ImageUpload from "./ImageUpload";

function Upload(props) {
  const [loading, setLoading] = useState(false);
  const [imageType, setImageType] = useState("");

  const malnutration = () => {
    setLoading(true);
    window.location.href = "/upload-malnutrition";
  };

  const disease = () => {
    setLoading(true);
    window.location.href = "/upload-disease";
  };

  const pest = () => {
    setLoading(true);
    window.location.href = "/upload-pest";
  };

  //////////////////////////////////////////////////////////////////////////////////////////////
  const [modalIsOpen, setModalIsOpen] = useState(false);
  let typeOfPost;
  let valeIN;

  function openPopUp(valeIN) {
    // console.log("VALUE IN", valeIN);
    if (valeIN === 1) {
      setImageType("Malnutrition");
    }
    if (valeIN === 2) {
      setImageType("Disease");
    }
    if (valeIN === 3) {
      setImageType("Pest");
    }
    setModalIsOpen(true);
  }
  //////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <Modal isOpen={true} toggle={props.onCloseM} className={"sm"}>
        <ModalHeader toggle={props.onCloseM}>Select the category</ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12">
                <div style={{ padding: 20 }}></div>
              </div>
              <div className="col-12 col-md-12"></div>
            </div>

            <div>
              <div style={{ margin: "auto", maxWidth: "500px" }}>
                <br />
                <Row>
                  <Button
                    type="button"
                    color="primary1"
                    style={{ width: "70%", margin: "auto" }}
                    onClick={() => openPopUp(1)}
                  >
                    <h5>Nutrient Deficiency</h5>
                  </Button>
                </Row>
                <br />
                <Row>
                  <Button
                    type="button"
                    color="primary2"
                    style={{ width: "70%", margin: "auto" }}
                    onClick={() => openPopUp(2)}
                  >
                    <h5>Disease/Pest</h5>
                  </Button>
                </Row>
                <br />
                <Row>
                  <Button
                    type="button"
                    style={{ width: "70%", margin: "auto" }}
                    onClick={() => openPopUp(3)}
                    color="primary3"
                  >
                    <h5>Growth Rate</h5>
                  </Button>
                </Row>
                <br />
                <div className="col-12 col-md-12">
                  <div style={{ padding: 20 }}></div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="primary4"
            style={{ width: "70%", margin: "auto" }}
            onClick={props.onCloseU}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* MODAL */}
      <Modal isOpen={modalIsOpen}>
        <ModalHeader>New post</ModalHeader>

        <ModalBody>
          <ImageUpload type={imageType} />
        </ModalBody>
        <ModalFooter>
          <div className="col-12 col-md-12">
            <center>
              <Button
                type="button"
                className="btn-block"
                color="primary4"
                onClick={() => setModalIsOpen(false)}
              >
                Cancel
              </Button>
            </center>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Upload;

import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import { Container } from "reactstrap";
import EditUser from "./components/EditUser";
import SignUp from "./components/SignUp";
import ChangePassword from "./components/ChangePassword";
import Upload from "./components/Upload";
import PrivateRoute from "./components/PrivateRoute";
import ImageUpload from "./components/ImageUpload";
import Footer001 from "./components/Footer001";
import MainNavbar from "./components/MainNavbar";

function App() {
  var auth = false;

  if (sessionStorage.getItem("login")) {
    auth = true;
  } else {
    auth = false;
  }
  // testing
  console.log("LOLOLOLOLOLOLO", sessionStorage.getItem("username"));

  return (
    <div>
      <Container>
        <Router>
          {sessionStorage.getItem("username") != null && <MainNavbar />}
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/sign-up" component={SignUp} />
            <PrivateRoute path="/home" auth={auth} comp={Home} />
            {/* <PrivateRoute path="/edit-user" auth={auth} comp={EditUser} />{" "} */}
            <PrivateRoute
              path="/change-password"
              auth={auth}
              comp={ChangePassword}
            />
            <PrivateRoute path="/upload" auth={auth} comp={Upload} />
            <PrivateRoute
              path="/upload-malnutrition"
              auth={auth}
              comp={ImageUpload}
              type="Malnutrition"
            />
            <PrivateRoute
              path="/upload-pest"
              auth={auth}
              comp={ImageUpload}
              type="Pest"
            />
            <PrivateRoute
              path="/upload-disease"
              auth={auth}
              comp={ImageUpload}
              type="Disease"
            />
          </Switch>
        </Router>
      </Container>
      <Footer001 />
    </div>
  );
}

export default App;

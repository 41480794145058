import React from "react";
function Footer001() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12">
            <center>
              <span className="text-muted">
                Developed by <a href="https://app.cloudatik.com/">Cloudatik</a>.
              </span>
            </center>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer001;

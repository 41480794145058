import React, { useState } from "react";
import Header from "./Header";
import "../App.css";
import {
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import Spinner from "../components/Spinner";
import SignUp from "./SignUp";

function Login() {
  const [loading, setLoading] = useState(false);

  const register = () => {
    window.location.href = "sign-up";
  };

  const login = async () => {
    setLoading(true);

    const data = new FormData();

    const uname = document.getElementById("txt_username").value;
    const pword = document.getElementById("txt_password").value;

    data.set("username", uname);
    data.set("password", pword);

    const res = await fetch("https://apidlimage.cloudatik.com/token/", {
      method: "POST",
      body: data,
    });

    const d = await res.json();

    if (d["access"] == null) {
      alert("Invalid Credentials");
      window.location.href = "/";
    } else {
      sessionStorage.setItem("username", uname);
      sessionStorage.setItem("login", true);
      window.location.href = "/home";
    }
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      {loading && <Spinner />}
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12">
            <div style={{ padding: 10 }}></div>
          </div>
          <div className="col-12 col-md-12">
            <center>
              <img src="/images/logo.png" alt="logo" width="200" height="150" />
            </center>
          </div>
          <div className="col-12 col-md-12">
            <div style={{ padding: 15 }}></div>
          </div>

          <div className="col-1 col-md-4"></div>

          <div className="col-10 col-md-4">
            <center>
              <Input
                type="text"
                name="txt_username"
                id="txt_username"
                placeholder="Username"
              />
            </center>
          </div>
          <div className="col-12 col-md-12">
            <div style={{ padding: 5 }}></div>
          </div>
          <div className="col-1 col-md-4"></div>
          <div className="col-10 col-md-4">
            <center>
              <Input
                type="password"
                name="txt_password"
                id="txt_password"
                placeholder="Password"
              />
            </center>
          </div>
          <div className="col-12 col-md-12">
            <div style={{ padding: 10 }}></div>
          </div>
          <div className="col-2 col-md-5"></div>
          <div className="col-8 col-md-2">
            <center>
              <Button
                type="button"
                className="btn btn-success btn-block"
                onClick={login}
              >
                <h4>LOG IN</h4>
              </Button>
            </center>
          </div>
          <div className="col-12 col-md-12">
            <div style={{ padding: 5 }}></div>
          </div>
          <div className="col-2 col-md-5"></div>
          <div className="col-8 col-md-2">
            <center>
              <Button
                type="button"
                className="btn btn-info btn-block"
                // onClick={register}
                onClick={() => setModalIsOpen(true)}
              >
                <h4>SIGN UP</h4>
              </Button>
            </center>
          </div>
        </div>
      </div>
      {/* MODAL */}
      <Modal isOpen={modalIsOpen}>
        <ModalBody>
          <div className="col-12 col-md-12">
            <SignUp />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 col-md-12">
            <center>
              <Button
                type="button"
                className="btn-block"
                color="primary4"
                onClick={() => setModalIsOpen(false)}
              >
                Cancel
              </Button>
            </center>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Login;

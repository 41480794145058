import React, { useEffect, useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Spinner from "../components/Spinner";
import { Link, useHistory } from "react-router-dom";
import {
  BiUserCircle,
  BiUser,
  BiLogOut,
  BiLockAlt,
  BiHome,
  BiCamera,
  BiHomeAlt,
} from "react-icons/bi";
import EditUser from "./EditUser";
import Upload from "./Upload";
import ChangePassword from "./ChangePassword";

// FUNCTION START HERE

function MainNavbar() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const history = useHistory();
  const [load, setLoad] = useState(false);
  const [phoneView, setIsPhoneView] = useState(false);

  const LogOut = () => {
    // setLoad(true);
    sessionStorage.clear();
    window.location.href = "/";
  };

  const onCLickLoad = () => {
    setLoad(true);
  };

  // new

  const [modal, setModal] = useState(false);

  const [modalC, setModalC] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setIsPhoneView(true);
    } else {
      setIsPhoneView(false);
    }
  });
  //
  //
  //
  const [changeUserPassword, setchangeUserPassword] = useState(false);
  const toggleChangePassword = () => setchangeUserPassword(!changeUserPassword);

  const [uploadNewImg, setuploadNewImg] = useState(false);
  const toggleuploadNewImg = () => setuploadNewImg(!uploadNewImg);

  const [userInformEdit, setuserInformEdit] = useState(false);
  const toggleEditUserInfo = () => setuserInformEdit(!userInformEdit);
  //
  //
  //
  return (
    <div>
      <>{load && <Spinner />}</>
      <div className="header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6 col-md-1">
              <img src="/images/logo.png" alt="logo" width="125" height="80" />
            </div>

            <div className="col-4 col-md-3">
              <center>
                <UncontrolledDropdown>
                  <DropdownToggle color="primary4" caret>
                    <BiUserCircle size="1.7em" /> Hello,{" "}
                    {sessionStorage.getItem("username")}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem href="/home" onClick={onCLickLoad}>
                      <BiHome size="1.2em" /> &nbsp; Home
                    </DropdownItem>
                    <div className="dropdown-divider"></div>
                    {/* UPLOAD NEW IMAGE */}
                    <DropdownItem onClick={() => setuploadNewImg(true)}>
                      <BiCamera size="1.2em" /> &nbsp; Upload image
                    </DropdownItem>
                    {uploadNewImg && <Upload onCloseU={toggleuploadNewImg} />}

                    {/*  */}
                    <div className="dropdown-divider"></div>
                    {/* EDIT USER INFORMATION */}
                    <DropdownItem onClick={() => setuserInformEdit(true)}>
                      <BiUser size="1.2em" /> &nbsp; My Profile
                    </DropdownItem>
                    {userInformEdit && (
                      <EditUser onCloseM={toggleEditUserInfo} />
                    )}

                    {/* CHANGE PASSWORD */}
                    <div className="dropdown-divider"></div>
                    <DropdownItem onClick={() => setchangeUserPassword(true)}>
                      <BiLockAlt size="1.2em" /> &nbsp; Change Password
                    </DropdownItem>
                    {changeUserPassword && (
                      <ChangePassword onClose={toggleChangePassword} />
                    )}

                    {/*  */}
                    <div className="dropdown-divider"></div>
                    <DropdownItem onClick={LogOut}>
                      <BiLogOut size="1.2em" /> &nbsp; LOG OUT
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </center>
            </div>
            <div className="col-0 col-md-7"></div>
            <div className="col-0 col-md-1">
              <center>
                {!phoneView && (
                  <Button
                    color="primary4"
                    onClick={() => (window.location.href = "/home")}
                  >
                    <BiHomeAlt size="2em" />
                    <h6>Home</h6>
                  </Button>
                )}
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainNavbar;

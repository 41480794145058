import React from "react";
import { Route, Redirect } from "react-router-dom";

function PrivateRoute(props) {
  return (
    <>
      {props.auth ? (
        <Route
          path={props.path}
          component={() => <props.comp type={props.type} />}
        />
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
}

export default PrivateRoute;

import React, { useState } from "react";
import {
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import Header from "./Header";
import MainNavbar from "./MainNavbar";
import $ from "jquery";
import Spinner from "../components/Spinner";
var userData;

function EditUser(props) {
  const [load, setLoad] = useState(true);
  const onEditUser = (e) => {
    e.preventDefault();
    setLoad(true);
    fetch("https://apidlimage.cloudatik.com/core/updateUserRecord/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token 46eda2a3d2b03c10bb8320a429d54756da1d5588",
      },
      body: JSON.stringify({
        username: sessionStorage.getItem("username"),
        name: document.getElementById("txt_fullname").value,
        state: document.getElementById("txt_state").value,
        crop: document.getElementById("txt_crop").value,
        city: document.getElementById("txt_city").value,
        no_plant: document.getElementById("txt_noplant").value,
        farm_size: document.getElementById("txt_farmsize").value,
        technique: document.getElementById("txt_technique").value,
        mobile: document.getElementById("txt_mobilephone").value,
        country: document.getElementById("txt_country").value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoad(false);

        alert(data["message"]);
        window.location.href = "/home";
      })
      .catch((err) => {
        setLoad(false);
        alert(err);
      });
  };
  $.ajax({
    url: `https://apidlimage.cloudatik.com/core/getUserRecord/${sessionStorage.getItem(
      "username"
    )}/`,
    headers: {
      Authorization: "Token 46eda2a3d2b03c10bb8320a429d54756da1d5588",
    },
    success: function (data) {
      console.log("SSS", data["record"]);
      try {
        userData = data["record"][0];
        document.getElementById("txt_fullname").value = userData["name"];
        document.getElementById("txt_mobilephone").value = userData["mobile"];
        document.getElementById("txt_city").value = userData["city"];
        document.getElementById("txt_state").value = userData["state"];
        document.getElementById("txt_technique").value = userData["technique"];
        document.getElementById("txt_farmsize").value = userData["farm_size"];
        document.getElementById("txt_noplant").value = userData["no_plant"];
        document.getElementById("txt_crop").value = userData["crop"];
        document.getElementById("txt_country").value = userData["country"];
      } catch (error) {
        console.log(error);
      }
      setLoad(false);
    },
    error: function () {
      setLoad(false);
      alert("Fail to Fetch from Server.");
    },
  });
  return (
    <>
      <>{load && <Spinner />}</>
      <Modal isOpen={true} toggle={props.onCloseU} className={"sm"}>
        <ModalHeader toggle={props.onCloseU}>My profile</ModalHeader>
        <ModalBody>
          <Form onSubmit={onEditUser}>
            <FormGroup>
              <Label for="txt_fullname">Full Name</Label>
              <Input
                type="text"
                name="txt_fullname"
                id="txt_fullname"
                placeholder="Full Name"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="txt_mobilephone">Mobile Phone</Label>
              <Input
                type="text"
                name="txt_mobilephone"
                id="txt_mobilephone"
                placeholder="Mobile No"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="txt_city">City</Label>
              <Input
                type="text"
                name="txt_city"
                id="txt_city"
                placeholder="City"
              />
            </FormGroup>
            <FormGroup>
              <Label for="txt_state">State</Label>
              <Input
                type="text"
                name="txt_state"
                id="txt_state"
                placeholder="State"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="txt_country">Country</Label>
              <Input
                type="text"
                name="txt_country"
                id="txt_country"
                placeholder="Country"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="txt_crop">Crop Name</Label>
              <Input
                type="text"
                name="txt_crop"
                id="txt_crop"
                placeholder="Crop Name"
              />
            </FormGroup>
            <FormGroup>
              <Label for="txt_farmsize">Farm Size</Label>
              <Input
                type="text"
                name="txt_farmsize"
                id="txt_farmsize"
                placeholder="Farm Size"
              />
            </FormGroup>
            <FormGroup>
              <Label for="txt_noplant">No Plant</Label>
              <Input
                type="text"
                name="txt_noplant"
                id="txt_noplant"
                placeholder="No of Plant"
              />
            </FormGroup>
            <FormGroup>
              <Label for="txt_technique">Technique</Label>
              <Input
                type="select"
                name="txt_technique"
                id="txt_technique"
                required
              >
                <option value="Polybags">Polybags</option>
                <option value="Ground">Ground</option>
                <option value="Aquaponics">Aquaponics</option>
                <option value="Hydroponics">Hydroponics</option>
                <option value="Mushroom">Mushroom</option>
              </Input>
            </FormGroup>
            <center>
              <Button
                type="submit"
                className="btn btn-info"
                style={{ width: "100%", margin: "auto" }}
              >
                <h5>Update Details</h5>
              </Button>
            </center>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="primary4"
            style={{ width: "70%", margin: "auto" }}
            onClick={props.onCloseM}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
export default EditUser;

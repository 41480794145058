import PropTypes from "prop-types";

function Header(props) {
  return (
    <header>
      <center>
        <h1>{props.title}</h1>
      </center>
    </header>
  );
}

Header.defaultProps = {
  title: "Image Upload",
};

Header.propsType = {
  title: PropTypes.string,
};

export default Header;

import React, { useState } from "react";
import { InputGroup, Button, Form, FormGroup, Label, Input } from "reactstrap";

import Header from "./Header";
import MainNavbar from "./MainNavbar";

import Crops from "../crop.json";
import Spinner from "./Spinner";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-country-select/dist/react-bootstrap-country-select.css";
import CountrySelect from "react-bootstrap-country-select";

function SignUp() {
  const [otherCrop, setOtherCrop] = useState(false);
  const [load, setLoad] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [country, setCountry] = useState({});

  var accountSid = "AC33da75d1374923ddce10e6153da01787"; // Your Account SID from www.twilio.com/console
  var authToken = "8163a2a56b9650e3c6982e18e0ade56b"; // Your Auth Token from www.twilio.com/console

  var twilio = require("twilio");
  var client = new twilio(accountSid, authToken);

  const getTAC = () => {
    window.scrollTo(0, 0);
    setLoad(true);
    var mobile_no = phoneNumber;

    client.verify
      .services("VAa87e43772ef7b2a8629b9352f846effe")
      .verifications.create({ to: mobile_no, channel: "sms" })
      .then((verification) => {
        setLoad(false);
        alert("TAC sent. Please check your phone.");
      })
      .catch((err) => {
        setLoad(false);
        alert(err);
      });
  };

  const onCropChange = (e) => {
    console.log(document.getElementById("cropDropDown").value);
    if (document.getElementById("cropDropDown").value == "Others") {
      setOtherCrop(true);
    } else {
      setOtherCrop(false);
    }
  };

  const onSignUp = (e) => {
    e.preventDefault();

    window.scrollTo(0, 0);
    setLoad(true);

    var mobile_no = phoneNumber;
    var tac = document.getElementById("txt_tac").value;
    var crop_name = "";

    var farm = "";
    var plant = "";

    if (otherCrop) {
      crop_name = document.getElementById("txt_crop").value;
    } else {
      crop_name = document.getElementById("cropDropDown").value;
    }

    if (document.getElementById("farmSize").value == "1") {
      plant = document.getElementById("txt_farmsize").value;
    } else {
      farm = document.getElementById("txt_farmsize").value;
    }

    console.log(
      JSON.stringify({
        username: document.getElementById("txt_username2").value,
        password: document.getElementById("txt_password2").value,
        name: document.getElementById("txt_fullname").value,
        state: document.getElementById("txt_state").value,
        crop: crop_name,
        city: document.getElementById("txt_city").value,
        no_plant: plant,
        farm_size: farm,
        technique: document.getElementById("txt_technique").value,
        mobile: phoneNumber,
        country: country["name"],
      })
    );

    client.verify
      .services("VAa87e43772ef7b2a8629b9352f846effe")
      .verificationChecks.create({ to: mobile_no, code: tac })
      .then((verification_check) => {
        var status = verification_check.status;
        console.log(status);
        console.log(country["name"]);
        console.log(phoneNumber);
        if (status == "approved") {
          fetch("https://apidlimage.cloudatik.com/registerNewUser/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token 46eda2a3d2b03c10bb8320a429d54756da1d5588",
            },
            body: JSON.stringify({
              username: document.getElementById("txt_username2").value,
              password: document.getElementById("txt_password2").value,
              name: document.getElementById("txt_fullname").value,
              state: document.getElementById("txt_state").value,
              crop: crop_name,
              city: document.getElementById("txt_city").value,
              no_plant: plant,
              farm_size: farm,
              technique: document.getElementById("txt_technique").value,
              mobile: phoneNumber,
              country: country["name"],
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              setLoad(false);
              alert(data["message"]);
              console.log(data);
              // window.location.href = "/";
            })
            .catch((err) => {
              setLoad(false);
              alert(err);
            });
        } else {
          setLoad(false);
          alert("Invalid Phone Number");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
        alert("Invalid TAC/Phone Number");
      });
  };

  const onCheckPhoneNumber = (e) => {
    if (e != undefined) {
      if (e.length >= 10 && e.length <= 14) {
        setbuttonTACIsOFF(false);
      } else {
        setbuttonTACIsOFF(true);
      }
    }
    setPhoneNumber(e);
  };

  const [buttonTACOFF, setbuttonTACIsOFF] = useState(true);

  return (
    <>
      <>
        {load && <Spinner />}
        <Form onSubmit={onSignUp}>
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-12">
                <div style={{ padding: 10 }}></div>
              </div>
              <div class="col-12 col-md-12">
                <center>
                  <a href="/">
                    <img
                      src="/images/logo.png"
                      alt="logo"
                      width="200"
                      height="150"
                    />
                  </a>
                </center>
              </div>
              <div class="col-12 col-md-12">
                <div style={{ padding: 15 }}></div>
              </div>
              <div class="col-1 col-md-4"></div>
              <div class="col-10 col-md-12">
                <center>
                  {/* FULL NAME */}
                  <Input
                    type="text"
                    name="txt_fullname"
                    id="txt_fullname"
                    placeholder="Full Name"
                    required
                  />
                </center>
              </div>
              <div class="col-12 col-md-12">
                <div style={{ padding: 5 }}></div>
              </div>
              <div class="col-1 col-md-4"></div>
              <div class="col-10 col-md-12">
                <center>
                  {/* USERNAME */}
                  <Input
                    pattern="[a-z0-9]*"
                    title="Small Letter and Number Only"
                    type="text"
                    name="txt_username2"
                    id="txt_username2"
                    placeholder="Username"
                    required
                  />
                </center>
              </div>
              <div class="col-12 col-md-12">
                <div style={{ padding: 5 }}></div>
              </div>
              <div class="col-1 col-md-4"></div>
              <div class="col-10 col-md-12">
                <center>
                  {/* PASSWORD */}
                  <Input
                    type="password"
                    name="txt_password2"
                    id="txt_password2"
                    placeholder="Password"
                    required
                  />
                </center>
              </div>
              <div class="col-12 col-md-12">
                <div style={{ padding: 5 }}></div>
              </div>
              <div class="col-1 col-md-4"></div>
              <div class="col-10 col-md-12">
                <center>
                  {/* PHONE NUMBER */}
                  <PhoneInput
                    style={{
                      paddingLeft: "0.4em",
                      paddingRight: "0.4em",
                      paddingTop: "0.3em",
                      paddingBottom: "0.3em",
                      borderRadius: "0.2em",
                      border: "1px solid #cfcfcf",
                      backgroundColor: "#fcfcfc",
                      outline: "none",
                      appearance: "none",
                    }}
                    defaultCountry="MY"
                    placeholder="Enter Phone Number"
                    value={phoneNumber}
                    onChange={onCheckPhoneNumber}
                  />
                </center>
              </div>

              <div class="col-12 col-md-12">
                <div style={{ padding: 5 }}></div>
              </div>
              <div class="col-1 col-md-4"></div>
              <div class="col-10 col-md-12">
                {/* TAC */}
                <InputGroup>
                  <Input
                    type="text"
                    name="txt_tac"
                    id="txt_tac"
                    placeholder="TAC"
                  ></Input>
                  {/* DISABLING TAC BUTTON */}
                  <Button
                    disabled={buttonTACOFF}
                    type="button"
                    onClick={getTAC}
                    className="btn btn-info"
                    style={{ width: 70 }}
                  >
                    <h6>GET</h6>
                  </Button>
                </InputGroup>
              </div>
              <div class="col-12 col-md-12">
                <div style={{ padding: 5 }}></div>
              </div>
              <div class="col-1 col-md-4"></div>
              <div class="col-10 col-md-12">
                <Input
                  type="text"
                  name="txt_city"
                  id="txt_city"
                  placeholder="City"
                />
              </div>
              <div class="col-12 col-md-12">
                <div style={{ padding: 5 }}></div>
              </div>
              <div class="col-1 col-md-4"></div>
              <div class="col-10 col-md-12">
                <Input
                  type="text"
                  name="txt_state"
                  id="txt_state"
                  placeholder="State"
                  required
                />
              </div>

              <div class="col-12 col-md-12">
                <div style={{ padding: 5 }}></div>
              </div>
              <div class="col-1 col-md-4"></div>
              <div class="col-10 col-md-12">
                <InputGroup>
                  <Input
                    type="select"
                    name="cropDropDown"
                    id="cropDropDown"
                    onChange={onCropChange}
                  >
                    {Crops.map((item) => (
                      <option key={item.id} value={item.crop}>
                        {item.crop}
                      </option>
                    ))}
                    <option value="Others">Others</option>
                  </Input>
                  {otherCrop && (
                    <div>
                      <Input
                        type="text"
                        name="txt_crop"
                        id="txt_crop"
                        placeholder="Crop Name"
                      />
                    </div>
                  )}
                </InputGroup>
              </div>
              <div class="col-12 col-md-12">
                <div style={{ padding: 5 }}></div>
              </div>
              <div class="col-1 col-md-4"></div>
              <div class="col-10 col-md-12">
                <InputGroup>
                  <Input
                    type="select"
                    name="farmSize"
                    id="farmSize"
                    style={{ width: "100%" }}
                  >
                    <option value="1">Number of plants</option>
                    <option value="2">Farm size(acre)</option>
                  </Input>
                  <Input type="text" id="txt_farmsize" name="txt_farmsize" />
                </InputGroup>
              </div>

              <div class="col-12 col-md-12">
                <div style={{ padding: 5 }}></div>
              </div>
              <div class="col-1 col-md-4"></div>
              <div class="col-10 col-md-12">
                <InputGroup>
                  <h6 style={{ padding: 5 }}>Technique: </h6>
                  <Input
                    type="select"
                    name="txt_technique"
                    id="txt_technique"
                    required
                  >
                    <option value="polybags">Polybags</option>
                    <option value="ground">Ground</option>
                    <option value="aquaponics">Aquaponics</option>
                    <option value="hydroponics">Hydroponics</option>
                    <option value="mushroom">Mushroom</option>
                  </Input>
                </InputGroup>
              </div>
              <div class="col-12 col-md-12">
                <div style={{ padding: 10 }}></div>
              </div>
              <div class="col-2 col-md-2"></div>
              <div class="col-8 col-md-8">
                <center>
                  <Button type="submit" className="btn btn-info btn-block">
                    <h4>SIGN UP</h4>
                  </Button>
                </center>
              </div>

              <div class="col-2 col-md-4"></div>
            </div>
          </div>
        </Form>
      </>
    </>
  );
}

export default SignUp;

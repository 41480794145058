import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Header from "./Header";

import Spinner from "./Spinner";
import { Link, useHistory } from "react-router-dom";

function ChangePassword(props) {
  const [load, setLoad] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const history = useHistory();
  const [phoneView, setIsPhoneView] = useState(false);
  const onCLickLoad = () => {
    setLoad(true);
  };

  const [modal, setModal] = useState(false);
  const toggleChangePassword = () => setModal(!modal);

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setIsPhoneView(true);
    } else {
      setIsPhoneView(false);
    }
  });

  const updatePassowrd = (e) => {
    setLoad(true);
    e.preventDefault();

    if (
      document.getElementById("txt_confirmpassword").value ==
      document.getElementById("txt_newpassword").value
    ) {
      fetch("https://apidlimage.cloudatik.com/core/updatePassword/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token d55751612bdfafb12d49e7c69dd4d6d8b326dc1a",
        },
        body: JSON.stringify({
          username: sessionStorage.getItem("username"),
          password: document.getElementById("txt_currentpassword").value,
          new_password: document.getElementById("txt_newpassword").value,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setLoad(false);
          alert(data["message"]);
          window.location.href = "/home";
        })
        .catch((err) => {
          setLoad(false);
          alert(err);
        });
    } else {
      setLoad(false);
      alert("Password Not Match");
    }
  };

  return (
    <>
      <Modal isOpen={true} toggle={props.onClose} className={"sm"}>
        <ModalHeader toggle={props.onClose}>Change password</ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12">
                <div style={{ padding: 10 }}></div>
              </div>
              <div className="col-12 col-md-2"></div>
              <div className="col-12 col-md-8">
                <Form onSubmit={updatePassowrd}>
                  <FormGroup>
                    <Input
                      type="password"
                      name="txt_currentpassword"
                      id="txt_currentpassword"
                      placeholder="Current Password"
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      type="password"
                      name="txt_newpassword"
                      id="txt_newpassword"
                      placeholder="New Password"
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      type="password"
                      name="txt_confirmpassword"
                      id="txt_confirmpassword"
                      placeholder="Confirm Password"
                      required
                    />
                  </FormGroup>

                  <button
                    type="submit"
                    className="btn btn-info btn-block"
                    style={{ width: "100%" }}
                  >
                    <h5>Update Password</h5>
                  </button>
                </Form>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary4"
            style={{ width: "60%", margin: "auto" }}
            onClick={props.onClose}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ChangePassword;

import React, { useState, useEffect } from "react";
import S3FileUpload from "react-s3";
import { BiCamera } from "react-icons/bi";
import Header from "./Header";
import Crops from "../crop.json";

import Spinner from "../components/Spinner";

import {
  Row,
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

function ImageUpload(props) {
  console.log("VALUE", props.type);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  var loading = true;
  const crop_list = [];
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [uploadBtnText, setUploadBtnText] = useState("");
  const [isPhoneView, setIsPhoneView] = useState(false);
  const [load, setLoad] = useState(false);
  const [dropDownCrop, setDropDownCrop] = useState([]);

  const [imageSize, setImageSize] = useState("");

  const onUpload = (e) => {
    window.scrollTo(0, 0);
    setLoad(true);
    const moment = require("moment");
    const datetime = moment().format("YYYY-MM-DD[T]HH:mm:ss");
    console.log(datetime);

    var file = imageFile;
    var blob = file.slice(0, file.size, file["type"]);
    var split = file["name"].split(".");
    var ext = split[split.length - 1];
    var newFileName = datetime + "_" + "01." + ext;
    var newFile = new File([blob], newFileName, { type: file["type"] });

    S3FileUpload.uploadFile(newFile, {
      bucketName: "dlcloudatik",
      dirName:
        sessionStorage.getItem("username") +
        "/" +
        document.getElementById("cropDropDown").value +
        "/" +
        props.type,
      region: "ap-southeast-1",
      accessKeyId: "AKIAJFU3NNSTCEYKFAHA",
      secretAccessKey: "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",
    })
      .then((data) => {
        console.log(data.location);
        console.log(datetime);
        fetch("https://apidlimage.cloudatik.com/image/saveImageRecord/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token d55751612bdfafb12d49e7c69dd4d6d8b326dc1a",
          },
          body: JSON.stringify({
            datetime: datetime,
            username: sessionStorage.getItem("username"),
            crop: document.getElementById("cropDropDown").value.toLowerCase(),
            category: props.type.toLowerCase(),
            identification: document.getElementById("txt_idetification").value,
            remark: document.getElementById("txt_remarks").value,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            setLoad(false);
            window.location.href = "/home";
            alert(data["message"]);
            //Go back to Darhboard/
          })
          .catch((err) => {
            setLoad(false);
            alert(err);
          });
      })
      .catch((err) => {
        setLoad(false);
        console.log(err);
        alert(err);
      });
  };

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (e) => {
    setImageFile(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
    console.log("IMAGE PREVIEW", imagePreview);
    if (isPhoneView) {
      setUploadBtnText("Retake Photo");
    } else {
      setUploadBtnText("Reupload Image");
    }
  };

  // PHONE OR PC?
  const phoneView = () => {
    if (window.innerWidth <= 960) {
      setIsPhoneView(true);
      setUploadBtnText("Take photo");
      setImageSize("100%");
    } else {
      setIsPhoneView(false);
      setUploadBtnText("Upload image");
      setImageSize("50%");
    }
  };

  useEffect(() => {
    fetch(
      `https://apidlimage.cloudatik.com/image/getCropMemory/${sessionStorage.getItem(
        "username"
      )}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token 46eda2a3d2b03c10bb8320a429d54756da1d5588",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        var response = data["crop"];
        var temp = [];
        Crops.map((item) => {
          if (
            item.crop ==
            response.charAt(0).toUpperCase() + response.slice(1)
          ) {
            var data = {
              id: item.id,
              language: item.language,
              crop: item.crop,
            };
            crop_list.push(data);
          } else {
            var data = {
              id: item.id,
              language: item.language,
              crop: item.crop,
            };
            temp.push(data);
          }
        });

        temp.map((item) => {
          var data = {
            id: item.id,
            language: item.language,
            crop: item.crop,
          };
          crop_list.push(data);
        });
        setDropDownCrop(crop_list);
        console.log(dropDownCrop);
      });

    phoneView();
  }, []);

  window.addEventListener("resize", phoneView);
  return (
    <>
      <>{load && <Spinner />}</>

      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12">
            <div style={{ padding: 10 }}></div>
          </div>
          <div className="col-12 col-md-12"></div>
        </div>

        <Form>
          <center>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleClick}
            >
              <BiCamera size="2em" /> <h5>{uploadBtnText}</h5>
            </button>

            <FormGroup>
              <input
                type="file"
                accept="image/*"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
              />
              <FormText color="muted">
                Please make sure the image is clear.
              </FormText>
            </FormGroup>
            {/* variative value */}
            {true && (
              <FormGroup>
                <img style={{ width: imageSize }} src={imagePreview} />
              </FormGroup>
            )}
          </center>
          <div className="row">
            <div className="col-12 col-md-4"></div>
            <div className="col-12 col-md-12">
              <FormGroup>
                <Label for="cropDropDown">Crop</Label>
                <Input type="select" name="cropDropDown" id="cropDropDown">
                  {dropDownCrop.map((item) => (
                    <option key={item.id} value={item.crop}>
                      {item.crop}
                    </option>
                  ))}
                </Input>
              </FormGroup>

              <FormGroup>
                <Label for="txt_ndetification">Indentification</Label>
                <Input
                  type="text"
                  name="txt_idetification"
                  id="txt_idetification"
                  placeholder="Identification"
                />
              </FormGroup>

              <FormGroup>
                <Label for="txt_remarks">Remarks</Label>
                <Input
                  type="textarea"
                  placeholder="Remarks"
                  name="txt_remarks"
                  id="txt_remarks"
                />
              </FormGroup>
              <center>
                <Button
                  type="button"
                  className="btn btn-success btn-block"
                  onClick={onUpload}
                >
                  <h5>CONFIRM</h5>
                </Button>
              </center>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

export default ImageUpload;

import React, { useState, useEffect } from "react";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import $ from "jquery";
import { BiCamera } from "react-icons/bi";
import Spinner from "../components/Spinner";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import Upload from "./Upload";

am4core.useTheme(am4themes_animated);

const moment = require("moment-timezone");

function formatDate(date) {
  console.log(date);
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
}

function Home() {
  var load = true;

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalMain, setModalMain] = useState(false);
  const toggleMain = () => setModalMain(!modalMain);

  const [modelData, setModalData] = useState([]);
  const [mainModalData, setMainModalData] = useState({});

  //
  //
  const [modalU, setModalU] = useState(false);
  const toggleUploadImage = () => setModalU(!modalU);

  const [uploadNewImg, setuploadNewImg] = useState(false);
  const toggleuploadNewImg = () => setuploadNewImg(!uploadNewImg);
  //
  //

  function viewSingleModel(id) {
    console.log(id);
    for (let index = 0; index < modelData.length; index++) {
      if (modelData[index]["id"] == id) {
        setMainModalData(modelData[index]);
        console.log(modelData[index]);
        // setModal(false);
        setModalMain(true);
        return;
      }
    }
  }

  const redirectUpload = () => {
    window.location.href = "/upload";
  };

  let colorSet = new am4core.ColorSet();
  colorSet.saturation = 0.6;

  const category_color = {
    malnutrition: {
      color: am4core.color("green"),
    },
    disease: {
      color: am4core.color("red"),
    },
    pest: {
      color: am4core.color("blue"),
    },
  };

  useEffect(() => {
    var index_pie_chart = 0;
    var temp_piechart = {};
    var temp_barchart = {};
    var data_piechart = [];
    var data_barchart = [];
    var data_timeline = [];
    var data_timeline_table = [];
    let temp_timeline = [];
    let date_list_timeline = [];
    var crop_list = [];

    $.ajax({
      url: `https://apidlimage.cloudatik.com/image/getImageRecord/${sessionStorage.getItem(
        "username"
      )}/all/3/`,
      headers: {
        Authorization: "Token 46eda2a3d2b03c10bb8320a429d54756da1d5588",
      },
      async: false,
      success: function (data) {
        data["event"].forEach((element) => {
          if (!crop_list.includes(element["crop"])) {
            crop_list.push(element["crop"]);
          }

          //JSON for timeline
          var malaysia_datetime = moment(element["datetime"])
            .tz("Asia/Kuala_Lumpur")
            .format("YYYY-MM-DD");

          let malaysia_timestamp = moment(element["datetime"])
            .tz("Asia/Kuala_Lumpur")
            .format("YYYY-MM-DD[T]HH:mm:ss");

          if (!date_list_timeline.includes(malaysia_datetime)) {
            date_list_timeline.push(malaysia_datetime);
          }

          var uri = encodeURI(
            `https://dlcloudatik.s3.amazonaws.com/${sessionStorage.getItem(
              "username"
            )}/${
              element["crop"].charAt(0).toUpperCase() + element["crop"].slice(1)
            }/${
              element["category"].charAt(0).toUpperCase() +
              element["category"].slice(1)
            }/${malaysia_timestamp}_01.jpg`
          );

          var data = {
            crop: element["crop"],
            start: malaysia_datetime,
            end: malaysia_datetime,
            identification: element["identification"],
            remark: element["remark"],
            url: uri,
            category: element["category"],
            color: category_color[element["category"]]["color"],
            
          };
          temp_timeline.push(data);

          //JSON for pie chart
          var cat;
          if (element["category"] == "malnutrition") {
            cat = "nutrient deficiency";
          } else {
            cat = element["category"];
          }

          if (temp_piechart[element["crop"]] == null) {
            temp_piechart[element["crop"]] = {};
            temp_piechart[element["crop"]]["disease"] = 0;
            temp_piechart[element["crop"]]["pest"] = 0;
            temp_piechart[element["crop"]]["nutrient deficiency"] = 0;
          }

          temp_piechart[element["crop"]][cat] += 1;

          // if (temp_piechart[element["crop"]][cat] == null) {
          //   temp_piechart[element["crop"]][cat] = 1;
          // } else {
          //   temp_piechart[element["crop"]][cat] += 1;
          // }

          //JSON for bar chart
          var date =
            String(element["datetime"]).split("-")[0] +
            "-" +
            String(element["datetime"]).split("-")[1];
          if (temp_barchart[element["crop"]] == null) {
            temp_barchart[element["crop"]] = {};
          }
          if (temp_barchart[element["crop"]][date] == null) {
            temp_barchart[element["crop"]][date] = {};
          }
          if (
            temp_barchart[element["crop"]][date][element["category"]] == null
          ) {
            temp_barchart[element["crop"]][date][element["category"]] = 1;
          } else {
            temp_barchart[element["crop"]][date][element["category"]] += 1;
          }
        });

        // Reconstruct Timeline Data
        console.log("DATETIME", date_list_timeline);

        for (let index = 0; index < date_list_timeline.length; index++) {
          data_timeline_table[index] = {};
          data_timeline_table[index]["date"] = date_list_timeline[index];
          data_timeline_table[index]["data"] = [];
        }

        for (let index = 0; index < temp_timeline.length; index++) {
          for (let m = 0; m < data_timeline_table.length; m++) {
            if (
              data_timeline_table[m]["date"] == temp_timeline[index]["start"]
            ) {
              let d = {
                id: index,
                category: temp_timeline[index]["category"],
                color: temp_timeline[index]["color"],
                crop: temp_timeline[index]["crop"],
                end: temp_timeline[index]["end"],
                identification: temp_timeline[index]["identification"],
                remark: temp_timeline[index]["remark"],
                start: temp_timeline[index]["start"],
                url: temp_timeline[index]["url"],
              };
              data_timeline_table[m]["data"].push(d);
            }
          }
        }

        console.log("CONSTRUCTED TABLE TIMELINE", data_timeline_table);

        for (let index = 0; index < data_timeline_table.length; index++) {
          let d = {
            date: data_timeline_table[index]["date"],
            start: data_timeline_table[index]["date"],
            end: data_timeline_table[index]["date"],
            number_image: data_timeline_table[index]["data"].length,
            category: "image",
          };
          data_timeline.push(d);
        }

        console.log("CONSTRUCTED TIMELINE", data_timeline);

        // Create 12 month JSON for bar chart
        console.log("TEMP_BARCHART", temp_barchart);
        var d = new Date();
        var ary_year = [];
        var year_list = [];
        var max_year = d.getFullYear();
        var min_year = parseInt(d.getFullYear()) - 1;
        var month = d.getMonth() + 1;

        ary_year.push(min_year);
        ary_year.push(max_year);

        for (let index = month; index <= 13; index++) {
          var data;
          if (index > 12) {
            for (let i = 1; i <= month; i++) {
              if (i < 10) {
                data = `${ary_year[1]}-${String(`0${i}`)}`;
              } else {
                data = `${ary_year[1]}-${i}`;
              }
              year_list.push(data);
            }
          } else {
            if (index < 10) {
              data = `${ary_year[0]}-${String(`0${index}`)}`;
            } else {
              data = `${ary_year[0]}-${index}`;
            }
            year_list.push(data);
          }
        }
        for (var crop of Object.keys(temp_barchart)) {
          for (var date of Object.keys(temp_barchart[crop])) {
            for (let index = 0; index < year_list.length; index++) {
              if (temp_barchart[crop][year_list[index]] == null) {
                temp_barchart[crop][year_list[index]] = {};
                temp_barchart[crop][year_list[index]]["id"] = index;
                temp_barchart[crop][year_list[index]]["disease"] = 0;
                temp_barchart[crop][year_list[index]]["pest"] = 0;
                temp_barchart[crop][year_list[index]]["malnutrition"] = 0;
              } else {
                temp_barchart[crop][year_list[index]]["id"] = index;
              }
            }
          }
        }

        console.log("TEMP PIECHART", temp_piechart);

        // Pie Chart
        for (var crop of Object.keys(temp_piechart)) {
          var temp_list = [];
          for (var category of Object.keys(temp_piechart[crop])) {
            var dat = {
              Category: category,
              Volume: temp_piechart[crop][category],
            };
            temp_list.push(dat);
          }
          data_piechart.push(temp_list);
          index_pie_chart += 1;
        }

        // Bar Chart
        //console.log(temp_barchart);
        for (var crop of Object.keys(temp_barchart)) {
          var temp_list = [];
          for (var date of Object.keys(temp_barchart[crop])) {
            var dat = {};
            dat["year"] = date;
            for (var details of Object.keys(temp_barchart[crop][date])) {
              if (dat[details] == null) {
                dat[details] = temp_barchart[crop][date][details];
              } else {
                dat[details] += temp_barchart[crop][date][details];
              }
            }
            temp_list.push(dat);
          }
          data_barchart.push(temp_list);
        }

        function sortByProperty(property) {
          return function (a, b) {
            if (a[property] > b[property]) return 1;
            else if (a[property] < b[property]) return -1;

            return 0;
          };
        }

        for (let index = 0; index < data_barchart.length; index++) {
          data_barchart[index].sort(sortByProperty("id"));
        }
      },
      error: function () {
        alert("There was an error.");
      },
      timeout: 60000,
    });

    for (let index = 1; index <= index_pie_chart; index++) {
      let myelement = `<div
        id=${"chartdiv" + index}
        style='width: 100%; height: 400px; margin: auto;'
      ></div>`;

      let myelement2 = `<div
        id=${"chartdiv" + (index + 99)}
        style='width: 100%; height: 450px; margin: auto;'
      ></div>`;

      $("#main").append(`<h2>${crop_list[index - 1]}</h2>`);
      $("#main").append(myelement);
      $("#main").append(myelement2);
      $("#main").append("<br>");
      $("#main").append("<hr>");
      $("#main").append("<br>");

      //Chart 1
      // let chart = am4core.create("chartdiv" + index, am4charts.PieChart3D);
      // chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
      // chart.legend = new am4charts.Legend();
      // chart.data = data_piechart[index - 1];
      // let series = chart.series.push(new am4charts.PieSeries3D());
      // series.dataFields.value = "Volume";
      // series.dataFields.category = "Category";

      let chart = am4core.create("chartdiv" + index, am4charts.PieChart);
      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "Volume";
      pieSeries.dataFields.category = "Category";
      chart.data = data_piechart[index - 1];
      // Let's cut a hole in our Pie chart the size of 30% the radius
      chart.innerRadius = am4core.percent(30);
      // Put a thick white border around each Slice
      pieSeries.slices.template.stroke = am4core.color("#fff");
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;
      // change the cursor on hover to make it apparent the object can be interacted with
      pieSeries.slices.template.cursorOverStyle = [
        {
          property: "cursor",
          value: "pointer",
        },
      ];
      pieSeries.alignLabels = false;
      pieSeries.labels.template.bent = true;
      pieSeries.labels.template.radius = 3;
      pieSeries.labels.template.padding(0, 0, 0, 0);
      pieSeries.ticks.template.disabled = true;
      // Create a base filter effect (as if it's not there) for the hover to return to
      let shadow = pieSeries.slices.template.filters.push(
        new am4core.DropShadowFilter()
      );
      shadow.opacity = 0;
      // Create hover state
      let hoverState = pieSeries.slices.template.states.getKey("hover"); // normally we have to create the hover state, in this case it already exists
      // Slightly shift the shadow and make it more prominent on hover
      let hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter());
      hoverShadow.opacity = 0.7;
      hoverShadow.blur = 5;

      // Chart 2
      let chart99 = am4core.create(
        "chartdiv" + (index + 99),
        am4charts.XYChart
      );
      chart99.padding(40, 40, 40, 40);

      let categoryAxis99 = chart99.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis99.renderer.grid.template.location = 0;
      categoryAxis99.dataFields.category = "year";
      // categoryAxis99.title.text = "Date";
      categoryAxis99.renderer.minGridDistance = 1;
      categoryAxis99.renderer.inversed = true;
      categoryAxis99.renderer.grid.template.disabled = true;

      let valueAxis = chart99.xAxes.push(new am4charts.ValueAxis());
      valueAxis.integersOnly = true;
      valueAxis.title.text = "Volume";

      function createSeries(field, name, stacked = true) {
        let series = chart99.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryY = "year";
        series.dataFields.valueX = field;
        series.name = name;
        series.stacked = stacked;
        series.columns.template.width = am4core.percent(95);

        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "({valueX})";
        labelBullet.label.fontSize = 10;
        labelBullet.label.fill = am4core.color("black");
        labelBullet.locationY = 0.5;
        labelBullet.locationX = 0.5;
      }

      createSeries("disease", "Disease");
      createSeries("malnutrition", "Nutrient Deficiency");
      createSeries("pest", "Pest");

      chart99.data = data_barchart[index - 1];

      // Add a legend
      chart99.legend = new am4charts.Legend();
    }

    //Chart 3
    let chart3 = am4core.create(
      "chartdiv99",
      am4plugins_timeline.SerpentineChart
    );
    chart3.curveContainer.padding(20, 20, 20, 20);
    chart3.levelCount = 8;
    chart3.orientation = "vertical";
    chart3.fontSize = 11;

    chart3.data = data_timeline;

    chart3.dateFormatter.dateFormat = "yyyy-MM-dd";
    chart3.dateFormatter.inputDateFormat = "yyyy-MM-dd";

    let categoryAxis2 = chart3.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis2.dataFields.category = "category";
    categoryAxis2.renderer.grid.template.disabled = true;
    categoryAxis2.renderer.labels.template.paddingRight = 25;
    categoryAxis2.renderer.minGridDistance = 10;
    categoryAxis2.renderer.innerRadius = -60;
    categoryAxis2.renderer.radius = 60;

    let dateAxis = chart3.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 70;

    let start_time = formatDate(
      new Date(new Date().setHours(new Date().getHours() + 12))
    );
    let endTime = formatDate(
      new Date(new Date().setMonth(new Date().getMonth() - 1))
    );
    dateAxis.min = new Date(endTime).getTime();
    dateAxis.max = new Date(start_time).getTime();

    dateAxis.renderer.tooltipLocation = 0;
    dateAxis.startLocation = -0.5;
    dateAxis.renderer.line.strokeDasharray = "1,4";
    dateAxis.renderer.line.strokeOpacity = 0.7;
    dateAxis.tooltip.background.fillOpacity = 0.2;
    dateAxis.tooltip.background.cornerRadius = 5;
    dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor(
      "alternativeBackground"
    );
    dateAxis.tooltip.label.paddingTop = 7;

    let labelTemplate = dateAxis.renderer.labels.template;
    labelTemplate.verticalCenter = "middle";
    labelTemplate.fillOpacity = 0.7;
    labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor(
      "background"
    );
    labelTemplate.background.fillOpacity = 1;
    labelTemplate.padding(7, 7, 7, 7);

    let categoryAxisLabelTemplate = categoryAxis2.renderer.labels.template;
    categoryAxisLabelTemplate.horizontalCenter = "left";
    categoryAxisLabelTemplate.adapter.add(
      "rotation",
      function (rotation, target) {
        let position = dateAxis.valueToPosition(dateAxis.min);
        return dateAxis.renderer.positionToAngle(position) + 90;
      }
    );

    let series1 = chart3.series.push(
      new am4plugins_timeline.CurveColumnSeries()
    );
    series1.columns.template.height = am4core.percent(20);

    series1.dataFields.openDateX = "start";
    series1.dataFields.dateX = "end";
    series1.dataFields.categoryY = "category";
    series1.columns.template.propertyFields.fill = "color"; // get color from data
    series1.columns.template.propertyFields.stroke = "color";
    series1.columns.template.strokeOpacity = 0;

    let bullet = new am4charts.CircleBullet();
    series1.bullets.push(bullet);
    bullet.circle.radius = 5;
    bullet.circle.strokeOpacity = 0;
    bullet.propertyFields.fill = "color";
    bullet.locationX = 0;
    bullet.states.create("active");

    bullet.events.on("hit", function (event) {
      let selectedDate = event.target.dataItem.dataContext["start"];

      for (let index = 0; index < data_timeline_table.length; index++) {
        if (data_timeline_table[index]["date"] == selectedDate) {
          setModalData(data_timeline_table[index]["data"]);
        }
      }

      // setModalDate(event.target.dataItem.dataContext["start"]);
      setModal(true);
    });

    chart3.scrollbarX = new am4core.Scrollbar();
    chart3.scrollbarX.align = "center";
    chart3.scrollbarX.width = am4core.percent(90);
    chart3.scrollbarX.scrollDuration = 10;

    let cursor = new am4plugins_timeline.CurveCursor();
    chart3.cursor = cursor;
    cursor.xAxis = dateAxis;
    cursor.yAxis = categoryAxis2;
    cursor.lineY.disabled = true;
    cursor.lineX.strokeDasharray = "1,4";
    cursor.lineX.strokeOpacity = 1;

    dateAxis.renderer.tooltipLocation2 = 0;
    categoryAxis2.cursorTooltipEnabled = false;
  }, []);

  load = false;

  return (
    <div>
      <>
        <Modal isOpen={modalMain} toggle={toggleMain} className={"sm"}>
          <ModalHeader toggle={toggleMain}>Details</ModalHeader>
          <ModalBody>
            <label>Date: {mainModalData["start"]}</label>
            <br />
            <label>Crop: {mainModalData["crop"]}</label>
            <br />
            <label>Category: {mainModalData["category"]}</label>
            <br />
            <label>Identification: {mainModalData["identificatiln"]}</label>
            <br />
            <label>Remarks: {mainModalData["remark"]}</label>
            <br />
            <img width="100%" src={mainModalData["url"]}></img>
          </ModalBody>
          <ModalFooter>
            <div className="col-12 col-md-12">
              <center>
                <Button
                  color="primary4"
                  style={{ width: "70%", margin: "auto" }}
                  onClick={toggleMain}
                >
                  Close
                </Button>
              </center>
            </div>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modal} toggle={toggle} className={"sm"}>
          <ModalHeader toggle={toggle}>Summary details</ModalHeader>
          <ModalBody>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Crop</th>
                  <th>Category</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {modelData.map((item) => (
                  <tr>
                    <td>{item.crop}</td>
                    <td>{item.category}</td>
                    <td>
                      <Button
                        onClick={() => viewSingleModel(item.id)}
                        color="info"
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <div className="col-12 col-md-12">
              <center>
                <Button
                  color="primary4"
                  style={{ width: "70%", margin: "auto" }}
                  onClick={toggle}
                >
                  Close
                </Button>
              </center>
            </div>
          </ModalFooter>
        </Modal>
      </>
      <>{load && <Spinner />}</>

      <br />
      <center>
        <button
          onClick={() => setuploadNewImg(true)}
          className="btn btn-primary"
          type="button"
        >
          <BiCamera size="2em" /> <h5>Upload image</h5>
          {uploadNewImg && <Upload onCloseU={toggleuploadNewImg} />}
        </button>
      </center>
      <div>
        <Modal isOpen={modalU} toggle={toggleUploadImage} className={"sm"}>
          <ModalHeader toggle={toggleUploadImage}>
            Uploading new image
          </ModalHeader>
          <ModalBody>
            <Upload />
          </ModalBody>
          <ModalFooter>
            <div className="col-12 col-md-12">
              <center>
                <Button
                  color="primary4"
                  style={{ width: "70%", margin: "auto" }}
                  onClick={toggleUploadImage}
                >
                  Cancel
                </Button>
              </center>
            </div>
          </ModalFooter>
        </Modal>
      </div>

      <div id="chartdiv99" style={{ width: "100%", height: "500px" }}></div>
      <br />
      <hr />
      <div id="main"></div>
    </div>
  );
}

export default Home;
